import React from "react"
import { ParallaxProvider } from 'react-scroll-parallax';
import throttle from "lodash.throttle"

import { SEO, Layout, About, Intro, Media, Features, Comic } from "../components"
import { isDesktop } from "../utils/responsive";

class HomePage extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      parallax: isDesktop(),
    }
  }

  handleResize = () => {
    const { parallax } = this.state;
    const enableParallax = isDesktop();
    if(enableParallax === parallax) {
      return;
    }
    this.setState({
      parallax: enableParallax
    })
  }

  throttleResize = throttle(this.handleResize, 250);

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttleResize, false);
  }

  componentDidMount() {
    if(!window) return;
    window.addEventListener('resize', this.throttleResize, false);
  }

  render() { 
    const { parallax } = this.state;
    return (
      <>
        <SEO title="Overgrowth by Wolfire Games" />
        <ParallaxProvider>
          <Layout contentPage={false}>
            <Intro parallaxOn={parallax} />
            <About parallaxOn={parallax} />
            <Media parallaxOn={parallax} />
            <Features parallaxOn={parallax} />
            <Comic />
          </Layout>
        </ParallaxProvider>
      </>
    )
  }
}

export default HomePage
